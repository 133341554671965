<script setup>
import { computed } from 'vue';

// define props
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    required: false,
    default: 'medium',
  },
});

// define computed
const cardClass = computed(() => ['product-catalog', `size-${props.size}`]);
</script>

<template>
  <q-card flat :class="cardClass" class="q-pa-xs">
    <router-link class="router-link" :to="`/product/${ product.slug }`">
      <div class="image-container">
        <img class="product-image" :src="product.image" :title="product.title" :alt="product.title"/>
      </div>
      <q-card-section class="product-section product-name">{{ product.title }}</q-card-section>
    </router-link>
  </q-card>
</template>

<style lang="scss">
@import '~quasar-variables';

.product-catalog {
  // commons settings
  width: 100%;
  &:hover .image-container { transform: scale(1.1); }
  .router-link { color: black; text-decoration: none; }
  .image-container {
    transition: all .2s;
    text-align: center;
    .product-image { width: 100%; height: auto; }
  }
  .product-section {
    padding: .2rem 0;
    &.product-name { color: $blue-grey-8; }
  }
  // settings per size
  &.size-small {
    max-width: 200px;
    .product-image { max-width: 100px; }
    .product-name { font-size: .8rem; }
  }
  &.size-medium {
    max-width: 230px;
    .product-image { max-width: 130px; }
    .product-name { font-size: .9rem; }
  }
  &.size-large {
    max-width: 270px;
    .product-image { max-width: 170px; }
    .product-name { font-size: 1rem; }
  }
}
</style>
