<script setup>
import { computed } from 'vue';
import ProductCatalog from './product-catalog.vue';

const props = defineProps({
  products: {
    type: Array,
    default: () => [],
  },
  size: {
    type: String,
    default: 'medium',
  },
  asGrid: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Number,
    default: 0,
  },
});

const gridClasses = computed(() => (props.asGrid ? ['as-greed', props.size] : ''));
</script>

<template>
  <div
    class="products-list q-pa-sm"
    :class="gridClasses"
  >
    <div
      v-for="(product, index) in products"
      :key="index"
      class="product-container row justify-around q-pa-sm"
    >
      <ProductCatalog
        v-if="items === 0 || index < items"
        :product="product"
        :size="size"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import '~quasar-variables';

// grid breakpoints and cols
$small-breakpoints: ("400px": 2, "600px": 3, "800px": 4, "1000px": 5);
$medium-breakpoints: ("460px": 2, "690px": 3, "920px": 4, "1150px": 5);
$large-breakpoints: ("540px": 2, "810px": 3, "1080px": 4);

// set media queries
@mixin set-grid-cols($list-breakpoints) {
  @each $size, $cols in $list-breakpoints {
    @media (min-width: $size) {
      grid-template-columns: repeat($cols, 1fr);
    }
  }
}

// styles
.products-list.as-greed {
  display: grid;
  grid-gap: 1rem;
  &.small { @include set-grid-cols($small-breakpoints) }
  &.medium { @include set-grid-cols($medium-breakpoints) }
  &.large { @include set-grid-cols($large-breakpoints) }
}
</style>
