<script setup>
/* eslint import/no-unresolved: */
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import { Notify } from 'quasar';
import PageLayout from '@/components/layouts/page-layout';
import SiteHeader from '@/components/widgets/site-header';
import SidebarBrands from '@/components/widgets/sidebar-brands';
import ProductList from '@/components/product/product-list';

const store = useStore();
const route = useRoute();

const catalogPage = ref([]);
const loading = ref(true);
const loadSettings = {
  loadingMessage: 'loading products...',
  height: '70vh',
};
const loadData = (to) => {
  const catalogParams = to ? to.params : route.params;
  // loading.value = true;
  store.dispatch('catalogPage', catalogParams)
    .then((response) => {
      catalogPage.value = response;
      loading.value = false;
    })
    .catch((error) => {
      Notify.create({ type: 'app-error', message: error.message });
      loading.value = false;
    });
};
onBeforeRouteUpdate(async (to, from, next) => {
  await loadData(to);
  return next();
});
loadData();
</script>

<template>
  <SiteHeader />
  <PageLayout
    :loading="loading"
    :load-settings="loadSettings"
    sidebar-right
  >
    <template #mainContent>
      <div class="page-header">
        <div v-if="catalogPage.term.image">
          <img
            :src="catalogPage.term.image"
            alt=""
            class="brand-logo"
          />
          <div class="brand-descr">
            {{ catalogPage.term.descr }}
          </div>
        </div>
        <p
          v-else
          class="page-title q-mt-md"
        >
          {{ catalogPage.term.name }}
        </p>
      </div>
      <ProductList
        :products="catalogPage.products"
        size="large"
        as-grid
      />
    </template>
    <template #rightSidebar>
      <SidebarBrands />
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import '~quasar-variables';

.page-header {
  .brand-logo { max-width: 250px; height: auto; }
  .brand-descr { padding: .5rem; color: $blue-grey-8; font-style: italic; font-size: 1rem; }
  .page-title { font-size: 2rem; letter-spacing: 1px; color: $blue-grey-8; }
}
</style>
